@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.decreasing-price-stamp {
	padding: 8px 10px;
	background: white;
	display: inline; // pour aligner prix label icon
	border-top-left-radius: $border-radius-big;
	height: 35px;
	box-sizing: border-box;
	color: $black;

	@include gridle_state(md) {
		padding: 6px 10px;
	}

	&__icon {
		height: 100%;
		display: inline-flex;
		align-items: center;
		margin-right: 5px;
	}

	&__label {
		@extend %font-bold;

		color: $black;
		font-size: 1rem;
		display: inline; // pour aligner prix label icon

		> * {
			display: inline; // pour aligner prix label icon
		}
	}

	&__separator {
		margin-left: 10px;
		margin-right: 10px;
		font-size: 1.6rem;
		font-weight: lighter;
		display: inline; // pour aligner prix label icon

		@include gridle_state(md) {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	&__price {
		display: inline;

		.amount {
			display: inline;
			color: $black;
			font-size: 1rem;

			> * {
				display: inline; // pour aligner prix label icon
			}

			&__prefix {
				font-size: 1rem;
				color: $black;
			}

			&__value {
				font-size: 1.6rem;
			}
		}
	}
}
